import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  rightsYear: number = 2018;
  getDate(){
    return this.rightsYear = new Date().getFullYear(); 
  }
  constructor() { }

  ngOnInit() {
    this.getDate()
  }

}
