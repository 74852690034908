import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {

  }
  // Get Slider element
  @ViewChild('sliderContainer', {static: false} ) sliderContainer: ElementRef;
  @ViewChild('sliderNav', {static: false} ) sliderNav: ElementRef;

  slider(delay:number) {
    // Jefferson Iyobosa lightweight slider - @github.com/frontend-io
    let sliderWrapper = this.sliderContainer.nativeElement;
    let slides = this.sliderContainer.nativeElement.querySelectorAll('li');
    let slideWidth = slides[0].parentElement.scrollWidth ;
    let sliderNavTargets = this.sliderNav.nativeElement.querySelectorAll('a');
    let counter = 0;

    // Display the first slide
    slides.forEach(slide => {
      slide.classList.remove('visible');
      if(slide.tabIndex == 0){
        slide.classList.add('visible');
        sliderNavTargets[0].classList.add('active');
      }
    });

    // Slider Logic
    function startSlide():void{
      (counter == slides.length +  1 )? counter = 0 : counter++;
      sliderNavTargets.forEach( target => {
        target.classList.remove('active');
        if (counter != sliderNavTargets.length) {
          sliderNavTargets[counter].classList.add('active');
          target.addEventListener('click', ()=>{
            counter = target.tabIndex - 1;
          });
          slides.forEach(slide => {
            slide.classList.remove('visible');
            if(slide.tabIndex == counter){
              slide.classList.add('visible');
              slide.style.transform = `translateX(0%)`;
              slide.style.visibility = "visible";
              // Rotate the container
              sliderWrapper.style.transform = "rotateY(180deg)";
              sliderWrapper.style.transition = ".8s";
              setTimeout(()=>{
                sliderWrapper.style.transform = "rotateY(0deg)";
              }, 500)
            }else{
              sliderWrapper.style.transform = `translateX(-${150}%)`;

              // Give the illusion that the slider is rotating by moving the passing-out slide to the right again while hidden
              // Do make sure your 'delay' is greater than the delay of this function to avoid misbehaviour of the slide
              setTimeout(()=>{
                  slide.style.visibility = "hidden";
                  slide.style.transform = `translateX(${150}%)`;
              }, 500);
            }
          });
        }else{
          counter = 0;
        }
      });
      
    };
    let timer;

    // Slide timing
    let slideControl = 
    {
        slideOn: ()=>{
            timer = setInterval(startSlide, delay);
        },
        slideOff: ()=>{
            clearInterval(timer);
        }

    }
    // Initialize the slider
    slideControl.slideOn();

    // Events on the slider;
    sliderWrapper.addEventListener('mouseover',()=>{
        slideControl.slideOff();
    });
    sliderWrapper.addEventListener('mouseleave', ()=>{
        slideControl.slideOn();
    })

  }; //End of slider

 
  ngAfterViewInit(){
    this.slider(5000)
    
    
  }

}
