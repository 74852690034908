import { Component, AfterViewInit,ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  constructor(
    private elementRef: ElementRef
  ) {}

  // Query the elements in the DOM
  @ViewChild( 'navWrap', {static: false}) navWrap: ElementRef;
  @ViewChild( 'close', {static: false}) hideNav: ElementRef;
  @ViewChild( 'bars', {static: false}) openNav: ElementRef;

  
  ngAfterViewInit(){
     // Nav Control
      let navigationControl = {
          open: function showNav(nav,closeIcon){
                  var links: any = nav.nativeElement.lastElementChild.querySelectorAll('a');
                  links.forEach(link => {
                    link.style.height = '20px';
                    link.style.padding = '20px 10px';
                  });
                  nav.nativeElement.style.height = "100vh";
                  setTimeout(()=>{
                    closeIcon.nativeElement.classList.add('animate');
                  }, 1000)
                },
          close: function showNav(nav,closeIcon){
            var links: any = nav.nativeElement.lastElementChild.querySelectorAll('a');
            links.forEach(link => {
              link.style.height = '0px';
              link.style.padding = '0px';
            });
            // Delay the closure of the menu
            setTimeout(() => {
              nav.nativeElement.style.height = "0vh";
            }, 700);
            // Delay the rotate animation
            setTimeout(()=>{
              closeIcon.nativeElement.classList.remove('animate');
            }, 600)
          },
          
        };
      
    // TOggle Nav open 
    this.openNav.nativeElement.addEventListener('click', ()=>{
      navigationControl.open(this.navWrap, this.hideNav);
    })
    // TOggle Nav close 
    this.hideNav.nativeElement.addEventListener('click', ()=>{
      navigationControl.close(this.navWrap, this.hideNav);
    })


  }
    
  ngOnInit() {

  }

}
