import { Injectable } from '@angular/core';
import { Contact } from '../models/contact';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';





@Injectable({
  providedIn: 'root'
})
export class ContactService {
  // public contactus_name;
  // public contactus_email;
  // public contactus_organization;
  // public contactus_message;
  contact: Contact

  
  
  constructor(private http: HttpClient, private router: Router,
    private _flashMessagesService: FlashMessagesService) { }

  // Contact us method
  // contactUs() {  
  //    const formData = {
  //     'name': this.contactus_name,
  //     'email': this.contactus_email,
  //     'organization': this.contactus_organization,
  //     'message': this.contactus_message     
  //      }
  //      console.log(formData);
  //    this.http.post("https://gudlifeapi.gudlifetech.com/api/public/contact", 
  //    JSON.stringify(formData), ).subscribe(data =>{
  //      console.log(data)
  //      if(data){
  //       this._flashMessagesService.show('Message sent successfully!!!', {cssClass: 'alert-success', timeout: 4000});
  //      }else{
  //       this._flashMessagesService.show('Something went wrong!!', {cssClass: 'alert-danger', timeout: 4000});      
  //      }
  //      this.contactus_name = '';
  //      this.contactus_email = '';
  //      this.contactus_organization = '';
  //      this.contactus_message = '';  
  //    },
  //      err => {
  //        console.log(err);
  //        this.contactus_name = '';
  //        this.contactus_email = '';
  //        this.contactus_organization = '';
  //        this.contactus_message = '';    
  //      }
  //    );
  // }

 
  conctactUs(contact: any) {
    console.log(contact);
  
    return this.http.post("https://gudlifeapi.gudlifetech.com/api/public/contact", contact);
  }

}
