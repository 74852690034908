import { Component, OnInit } from '@angular/core';
import { ContactService } from '../services/contact.service';
import { Contact } from 'src/app/models/contact';
// import { NgForm } from "@angular/forms";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact: Contact;
  contactForm: FormGroup;
  isLoading = false;
  // form: NgForm;
  

  constructor(private contactservice: ContactService, private fb: FormBuilder,
    private flashMessage: FlashMessagesService) { }

  ngOnInit() {
    this.contactUsForm();
  }
  
  // Contact us method
//   contactUs(){
 
//   this.contact.name = this.contactservice.contactus_name;
//   this.contact.email = this.contactservice.contactus_email;
//   this.contact.organization = this.contactservice.contactus_organization;
//   this.contact.message = this.contactservice.contactus_message;

//   this.contactservice.contactUs(this.contact)
//   .subscribe((res) => {
//     alert('Message sent!!!')
//     console.log(res);
//     this.form.resetForm();
//   }, error => {
//     alert('error, something went wrong');
//   });
//   console.log(this.contact);
//   this.form.resetForm(); 
// }


contactUsForm() {
  this.contactForm = this.fb.group({
   name: ['', Validators.required],
   email: ['', Validators.required],
   organization: ['', Validators.required],
   message: ['', Validators.required]
 });
}



private prepareSave(): any {
  let input = new FormData();
  input.append('name', this.contactForm.get('name').value);
  input.append('email', this.contactForm.get('email').value);
  input.append('organization', this.contactForm.get('organization').value);
  input.append('message', this.contactForm.get('message').value);
  return input;
}

private prepareClear(): any {
  let input = new FormData();
  input.append('name', '');
  input.append('email', '');
  input.append('organization', '');
  input.append('message', '');
  return input;
}

  //Contact us
  contacting() {
    if (this.contactForm.valid) {
      const formModel = this.prepareSave();
      this.isLoading = true;
      this.contactservice.conctactUs(formModel).subscribe((res) => {
        console.log(res);
        setTimeout(() => {
          this.flashMessage.show('Message sent!!', {cssClass: 'alert-success', timeout: 4000});
          // alert('message sent!!!');
          this.contactForm.reset();
          this.isLoading = false;
        }, 1000);

        console.log(1);
      }, error => {
        console.log(error);
        this.isLoading = false;
        this.contactForm.reset();
      }, () => {
         
      });
    } 
  }
// contactUs(){
//   this.contactservice.contactUs(this.contact);
// }
}
