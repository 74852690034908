import { Component } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Gudlife Technologies';

  ngOnInit() {
    AOS.init({
        offset: 400,
        duration: 1000
      });
  }

  
}
